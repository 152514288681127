<template>
    <div class="dict">
    <div class="form-header-btn">
      <el-page-header
        @back="goBack"
        :content="'新增待办'"
      >
        <template slot="title">
            <i class="el-icon-arrow-left"></i>返回
        </template>
      </el-page-header>
    </div>
        <!-- <div class="title">新增待办</div> -->
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  style="width: 80%" :label-width="labelWidth" class="ruleForm">
            <el-form-item label="任务名称：" prop="theme">
                <el-input v-model.trim="ruleForm.theme" :maxlength="200" placeholder="请输入任务名称"></el-input>
            </el-form-item>
            <el-form-item class="chooseTime" label="开始时间：" prop="startTime">
                <el-col :span="startTimeSpan">
                    <el-date-picker
                        style="width: 75%"
                        v-model="ruleForm.startTime"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="changeStartTime"
                        :picker-options="pickerOptions"
                        placeholder="请选择开始时间">
                    </el-date-picker>
                    <el-checkbox style="margin-left:0.52083vw" @change="changeEndBox" v-model="ruleForm.isEndTime">截止时间</el-checkbox>
                    <!-- <span class="jiezhi"></span> -->
                </el-col>
                <el-col :span="endTimeSpan">
                    <el-form-item class="endTime"  v-if="ruleForm.isEndTime" :label="ruleForm.isEndTime?'截止时间：':''"   prop="endTime">
                        <el-date-picker v-if="ruleForm.isEndTime"
                            v-model="ruleForm.endTime"
                            type="date"
                            @change="changeEndTime"
                            :picker-options="pickerEndOptions"
                            :default-time="'23:59:59'"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请选择截止时间">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-form-item>
            <el-form-item label="频次：" prop="itemTimes">
                <el-input type="number" min="1" v-input-number placeholder="请输入频次" :maxlength="3" onkeyup="if(value<=0)value=''" :disabled="!ruleForm.isEndTime"  v-model="ruleForm.itemTimes">
                    <template slot="append">次</template>
                </el-input>
            </el-form-item>
            <el-form-item label="周期：" prop="period">
                <!-- {{ruleForm.period}} -->
                <el-input type="number" placeholder="请输入周期" :maxlength="3" onkeyup="if(value<=0)value=''" v-if="!ruleForm.endTime" min="0" v-model="ruleForm.period">
                    <template slot="append">天</template>
                </el-input>
                <el-input type="number" v-else min="0" :disabled="ruleForm.endTime!=''"  ref="val" v-model="showNum">
                    <template slot="append">天</template>
                </el-input>
            </el-form-item>
            <el-form-item label="日历图标：" prop="iconPath">
                <el-popover
                    placement="right-start"
                    width="494"
                    popper-class="opIocn"
                    @show="showIcon"
                    trigger="click" v-model="dialogVisible">
                    <el-button slot="reference"  type="text">
                        <span v-if="ruleForm.iconPath == ''">选择图标</span>
                        <span v-else>更换图标</span>
                    </el-button>
                    <div slot="reference" v-if="ruleForm.iconPath" class="iconPathWrap" > 
                        <img :src="ruleForm.iconPath.scheduleIcon" alt="">
                    </div>
                    <div>
                        <div class="iconTitle">
                            <span>图标库</span>
                            <span class="el-icon-close" @click="dialogVisible = false"></span>
                        </div>
                        <div class="dialogDiv">
                            <div :class="iconPath.id == item.id ?'iconPathWrap active':'iconPathWrap'" @click="chooseIconPath(item)" v-for="item in iconPathList" :key="item.id">
                            <img :src="item.scheduleIcon" alt="">
                            </div>
                        </div>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="dialogVisible = false" class="reset">取 消</el-button>
                            <el-button type="primary" @click="chooseIconPathBtn">确 定</el-button>
                        </span>
                    </div>
                </el-popover>
            </el-form-item>
            <el-form-item label="" prop="name">
                <el-button class="reset" @click="goBack">取消</el-button>
                <el-button type="primary" @click="subBtn" :loading="addLoading" :disabled="addLoading">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    data(){
        var checkNum = (rule, value, callback) => {
            if (!value) {
                return callback();
            }
            let valueString = value + "";
            let numberAuth = /[^\d]/g;
            if (numberAuth.test(valueString)) {
                callback(new Error("只支持正整数"));
            }
            else {
                callback();
            }
        };
        return{
            pickerOptions: {
                disabledDate(time) {
                    let _now = new Date();
                    _now.setDate(_now.getDate() - 1)
                    console.log(_now)
                    return time.getTime() < _now.getTime();
                },
                selectableRange:  '08:00:00' + " - 18:00:00"
            },
            pickerEndOptions:{
                disabledDate(time) {
                    let _now = Date.now();
                    return time.getTime() < _now;
                },
            },
            rules:{
                theme:[{ required: true, message: "请输入任务名称", trigger: "blur" },],
                startTime:[{ required: true, message: "请选择开始时间", trigger: "blur" },],
                endTime:[{ required: true, message: "请选择截止时间", trigger: "blur" },],
                itemTimes:[
                    { required: true, message: "请输入频次", trigger: "blur" },
                    { validator: checkNum, trigger: 'blur' }
                ],
                period:[{ required: true, message: "请输入周期", trigger: "blur" },],
                iconPath:[{ required: true, message: "请选择日历图标", trigger: "blur" },],
            },
            ruleForm:{
                theme:'',
                startTime:'',
                endTime:'',
                itemTimes:1,
                period:'',
                isEndTime:false,
                iconPath:'',
            },
            iconPath:'',
            dialogVisible:false,
            iconPathList:[
                // {
                //     id:1,
                //     url:require('@/assets/img/detail-del-icon.png')
                // },
                // {
                //     id:2,
                //     url:require('@/assets/img/detail-del-icon.png')
                // },
                // {
                //     id:3,
                //     url:require('@/assets/img/detail-del-icon.png')
                // },
                // {
                //     id:4,
                //     url:require('@/assets/img/detail-del-icon.png')
                // },
            ],
            initVal:'',
            addLoading:false,
            labelWidth: '120px',
            startTimeSpan: 17,
            endTimeSpan: 5,
        }
    },
    computed: {
        showNum() {
            if(this.ruleForm.startTime && this.ruleForm.endTime && this.ruleForm.isEndTime && this.ruleForm.itemTimes){
                console.log(this.ruleForm.startTime.slice(0,10)+' 00:00:00')
                this.ruleForm.period = this.datedifference(this.ruleForm.startTime.slice(0,10)+' 00:00:00',this.ruleForm.endTime , this.ruleForm.itemTimes)
                console.log(this.ruleForm.period)
                // this.$forceUpdate()
                return this.datedifference(this.ruleForm.startTime.slice(0,10)+' 00:00:00',this.ruleForm.endTime , this.ruleForm.itemTimes)
            }else{
                // this.ruleForm.period = ''
                return ''
            }
        }
    },
    created(){
        this.initIconData()
    },
    mounted(){
        window.addEventListener("resize",(e) => {
            console.log(e)
            if(e.target.innerWidth < 1300){
                this.labelWidth = '80px'
                this.startTimeSpan = 17
                this.endTimeSpan = 7
            } else {
                this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
                this.startTimeSpan = 19
                this.endTimeSpan = 5
            }
            this.$forceUpdate()
        })
        if(window.innerWidth < 1300){
            this.labelWidth = '80px'
            this.startTimeSpan = 17
            this.endTimeSpan = 7
        } else {
            this.labelWidth = (120 / 1920 * window.innerWidth) + 'px'
            this.startTimeSpan = 19
            this.endTimeSpan = 5
        }
        
    },
    methods:{
        changeVal(val){
            this.initVal = val
        },
        goBack(){
            this.$router.go(-1)
        },
        changeStartTime(date){
            if(date){
                this.pickerEndOptions = {
                    disabledDate(time){
                        let _now = new Date(date+1).getTime();
                        return time.getTime() < _now;
                    }
                }
            }else{
                this.pickerEndOptions = {
                    disabledDate(time){
                        let _now = Date.now();
                        return time.getTime() < _now;
                    }
                }
            }
        },
        changeEndTime(date){
            this.ruleForm.period = ''
            setTimeout(()=>{
                this.$refs.ruleForm.clearValidate('period')
            },100)
            if(date){
                console.log(date)
                this.pickerOptions = {
                    disabledDate(time) {
                        let _now = Date.now();
                        let _end = new Date(date).getTime();
                        return time.getTime() < _now || time.getTime() > (_end);
                    },
                    selectableRange: '08:00:00'-'18:00:00'
                }
            }else{
                this.ruleForm.endTime = ''
                this.pickerOptions = {
                    disabledDate(time){},
                    selectableRange: '08:00:00'-'18:00:00'
                }
            }
        },
        changeEndBox(val){
            this.ruleForm.period = ''
            if(!val){
                this.ruleForm.endTime = ''
				this.ruleForm.itemTimes = 1
            }
        },
        subBtn(){
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    let parmas = JSON.parse(JSON.stringify(this.ruleForm))
                    if(!this.ruleForm.isEndTime){
                        parmas.endTime = ''
                    }
                    parmas.iconPath = parmas.iconPath.id
                    this.addLoading = true
                    this.$api.saveSelf(parmas).then(res=>{
                        if(res.data.code == '0'){
                            this.$message.success('添加成功')
                            this.$router.go(-1)
                        }else{
                            this.$message.error(res.data.msg)
                        }
                        this.addLoading = false
                    }).catch(error=>{
                        this.addLoading = false
                    })
                }
            })
        },
        chooseIconPath(item){
            this.iconPath = item
        },
        chooseIconPathBtn(){
            this.ruleForm.iconPath = this.iconPath
            this.dialogVisible = false
        },
        datedifference(sDate1, sDate2,pinci) {
            console.log(sDate1)
            var sdate = new Date(sDate1);
            var now = new Date(sDate2);
            var days = now.getTime() - sdate.getTime();
            console.log((days / (1000 * 60 * 60 * 24))+1)
            var day = Math.round(((days / (1000 * 60 * 60 * 24))+1)/pinci);
            if(day == 0){
                day = 1
            }
            return day;
        },
        showIcon(){
            if(this.ruleForm.iconPath){
                this.iconPath = this.ruleForm.iconPath
            }else{
                this.iconPath = ''
            }
        },
        initIconData(){
            this.$api.getAllIconList().then(res=>{
                if(res.data.code == '0'){
                    this.iconPathList = res.data.data
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
    },
    beforeDestroy(){
        window.removeEventListener("resize",(e) => {
            console.log(e)
            if(e.target.innerWidth < 1300){
                this.labelWidth = '80px'
                this.startTimeSpan = 17
                this.endTimeSpan = 7
            } else {
                this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
                this.startTimeSpan = 19
                this.endTimeSpan = 5
            }
            this.$forceUpdate()
        })
    }
}
</script>
<style lang="scss" scoped>
.dict{
    padding: 34px 30px 30px 30px;
    .title{
        color: #3D3E4F;
        font-size: 22px;
        font-weight: bold;
    }
    .ruleForm{
        margin-top: 28px;
    }
}
.iconTitle{
    display: flex;
    justify-content: space-between;
    padding: 2px 18px 14px;
    align-items: center;
    color: #3D3E4F;
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    .el-icon-close{
        color: #909399;
        font-size: 14px;
        cursor: pointer;
    }
}
.iconPathWrap{
    background: #fff;
    border: 1px solid #fff;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background: #EAF1FC;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    img{
        width: 24px;
        height: 24px;
    }
}
.dialogDiv{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    padding: 24px 18px;
    box-sizing: border-box;
    // .iconPathWrap{
    //     background: #fff;
    //     border: 1px solid #fff;
    //     cursor: pointer;
    //     width: 50px;
    //     height: 50px;
    //     background: #EAF1FC;
    //     border-radius: 8px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     margin-right: 10px;
    //     img{
    //         width: 24px;
    //         height: 24px;
    //     }
    // }
    .active{
        border: 1px solid #003685;
    }
}
.iconPath{
    width: 60px;
}
/deep/.el-dialog__header{
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.dialog-footer{
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
.opIocn{
  z-index: 1900 !important;
}
.jiezhi{
    margin-left: 5px;
}
</style>
<style>
.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
  display: none;
}
</style>